import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import jwtDecode from "jwt-decode";
import axios from "axios";

import { Gallery } from "components/guest/gallery/Gallery";
import { Home } from "components/guest/home/Home";
import { Footer } from "components/sub-components/footer/Footer";
import { NavigationBar } from "components/sub-components/navigation-bar/NavigationBar";
import { Shop } from "components/guest/shop/Shop";
import { CartContext } from "contexts/CartContext";
import { Cart } from "./shop/cart/Cart";
import { About } from "./about/About";
import { GuestLogin } from "./login/GuestLogin";
import { GuestSignUp } from "./login/GuestSignUp";
import { AuthContext } from "contexts/AuthContext";
import { CheckoutSuccessful } from "./shop/cart/CheckoutSuccessful";
import { CheckoutUnsuccessful } from "./shop/cart/CheckoutUnsuccessful";

export const Guest = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState<IUser>();
    const [cartItems, setCartItems] = useState<ICartItem[]>([]);

    useEffect(() => {
        checkAuth();
    }, []);

    useEffect(() => {
        const data = localStorage.getItem("cartItems");

        if (data) {
            const items = JSON.parse(data) as ICartItem[];
            setCartItems(items);
        }
    }, []);

    async function checkAuth() {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const refreshToken = localStorage.getItem("refreshToken");
            if (!accessToken || !refreshToken) {
                setLoggedIn(false);
            } else {
                const payload = jwtDecode<IPayload>(accessToken);
                if (payload.exp < new Date().getTime() / 1000) {
                    setLoggedIn(false);
                    const response = await axios.post(
                        "/api/auth/token",
                        { token: refreshToken },
                        {
                            headers: { "Content-Type": "application/json" },
                        }
                    );
                    if (response?.status === 200) {
                        localStorage.setItem(
                            "accessToken",
                            response.data.accessToken
                        );
                        setLoggedIn(true);
                        setUser?.({
                            userName: payload.userName,
                            role: payload.role,
                        });
                    }
                } else {
                    setLoggedIn(true);
                    setUser?.({
                        userName: payload.userName,
                        role: payload.role,
                    });
                }
            }
        } catch (error) {
            console.log(error);
            setLoggedIn(false);
        }
    }

    const AuthRoute = (props: { outlet: JSX.Element }) => {
        if (!loggedIn) {
            return props.outlet;
        } else {
            return <Navigate to={{ pathname: "/" }} />;
        }
    };

    return (
        <AuthContext.Provider value={{ loggedIn, setLoggedIn, user, setUser }}>
            <CartContext.Provider value={{ cartItems, setCartItems }}>
                <NavigationBar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="about" element={<About />} />
                    <Route path="gallery" element={<Gallery />} />
                    <Route path="shop/*" element={<Shop />} />
                    <Route
                        path="sign-in"
                        element={<AuthRoute outlet={<GuestLogin />} />}
                    />
                    <Route
                        path="sign-up"
                        element={<AuthRoute outlet={<GuestSignUp />} />}
                    />
                    <Route path="cart" element={<Cart />} />
                    <Route
                        path="*"
                        element={
                            <main style={{ padding: "1rem" }}>
                                <p>There's nothing here!</p>
                            </main>
                        }
                    />
                    <Route path="checkout-successful" element={<CheckoutSuccessful />} />
                    <Route path="checkout-unsuccessful" element={<CheckoutUnsuccessful />} />
                </Routes>
                <Footer />
            </CartContext.Provider>
        </AuthContext.Provider>
    );
};
