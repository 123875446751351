import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faInstagram,
    faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import jacob from "../../../assets/jacob.jpg";

export const About = () => {
    return (
        <div>
            <Container>
                <Card
                    className="my-5"
                    style={{
                        backgroundColor: "whitesmoke",
                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    }}
                >
                    <Row className="g-0 justify-content-md-center">
                        <Col xs={12} lg={6}>
                            <div
                                style={{
                                    width: "100%",
                                    height: "75vh",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundImage: `url(${jacob})`,
                                }}
                            ></div>
                        </Col>
                        <Col xs={12} lg={6}>
                            <div
                                style={{
                                    width: "100%",
                                    minHeight: "75vh",
                                    padding: "4rem",
                                }}
                            >
                                <h3 className="mb-4">Ekow Brew</h3>
                                <div
                                    className="mb-4"
                                    style={{ fontSize: "1.2rem" }}
                                >
                                    CONTEMPORARY VISUAL ARTIST
                                </div>
                                <div
                                    className="mb-4"
                                    style={{ fontSize: "0.9rem", opacity: 0.7 }}
                                >
                                    Ekow Brew is a Ghanaian born Contemporary
                                    Visual Artist. He is a self-taught artist
                                    who specializes in the new media arts forms
                                    (Digital Arts). His art pieces are in
                                    nature, surrealistic digital collages. He
                                    considers his works as an extension of his
                                    perceptions and expressions of how he views
                                    Life and his Surroundings. His style is
                                    experimental and gives him the freedom to
                                    explore the depths of his creativity. Every
                                    artwork he creates is an opportunity to
                                    rediscover himself, as he perceives life as
                                    a journey of constant exploration of
                                    oneself. His artworks gives his audience a
                                    sneak peek into his reality and perceptions.
                                    There's always an incorporation of an
                                    element of nature in his artworks as he
                                    tries to explore the relationships of the
                                    forces of nature in a figurative manner. He
                                    believes in the individualistic perceptions
                                    of arts hence he creates art that gives
                                    freedom to his audience to explore their
                                    view point of their understanding of the
                                    artwork.
                                </div>
                                <div>
                                    <FontAwesomeIcon
                                        className="me-4"
                                        icon={faTwitter}
                                    />
                                    <FontAwesomeIcon
                                        className="me-4"
                                        icon={faFacebook}
                                    />
                                    <FontAwesomeIcon
                                        className="me-4"
                                        icon={faInstagram}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </div>
    );
};
