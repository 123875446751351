import React, { useEffect, useState } from "react";
import { ArtPieceService } from "services/artPiece.service";
import { Lightbox } from "../../sub-components/lightbox/Lightbox";

import "./gallery.css";

const artPieceService = new ArtPieceService();

const splitToChunks = (array: IArtPieceResponse[], chunkSize: number) => {
    const chunks: IArtPieceResponse[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }

    return chunks;
};

export const Gallery = () => {
    const [artPieces, setArtPieces] = useState<IArtPieceResponse[]>([]);
    const [activeArtPiece, setActiveArtPiece] = useState<IArtPieceResponse>();

    const handleSetActiveImage = (artPiece: IArtPieceResponse) => {
        setActiveArtPiece(artPiece);
    };

    const handleRemoveActiveImage = () => {
        setActiveArtPiece(undefined);
    };

    const getArtPieces = async () => {
        try {
            const response = await artPieceService.getArtPieces();
            if (response?.status === 200) {
                setArtPieces(response.data);
            }
        } catch (error) {}
    };

    useEffect(() => {
        getArtPieces();
    }, []);

    return (
        <div>
            <div className="header">Gallery</div>

            <div className="my-5">
                {splitToChunks(artPieces, 6).map((chunk, idy) => (
                    <div className="container grid-container mb-3" key={idy}>
                        {chunk.map((piece, idx) => (
                            <div
                                key={idx}
                                className={`grid-item grid-item-${
                                    idx + 1
                                } image`}
                                style={{
                                    backgroundImage: `url(/api${piece.image})`,
                                }}
                                onClick={() => handleSetActiveImage(piece)}
                            />
                        ))}
                    </div>
                ))}
            </div>

            <Lightbox
                activeImage={activeArtPiece}
                images={artPieces}
                handleRemoveActiveImage={handleRemoveActiveImage}
                handleSetActiveImage={handleSetActiveImage}
            />
        </div>
    );
};
