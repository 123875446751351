import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import { AddToCartButton } from "../add-to-cart/AddToCart";
import { Loading } from "components/sub-components/loading/Loading";
import { ArtPieceService } from "services/artPiece.service";
import { RelatedArtPieces } from "../related-art-pieces/RelatedArtPieces";

import "./singleArtPiece.css";

const artPieceService = new ArtPieceService();

export const SingleArtPiece = () => {
    const { artPieceId } = useParams();
    const navigate = useNavigate();
    const [artPiece, setArtPiece] = useState<IArtPieceResponse | undefined>();
    const [loading, setLoading] = useState(false);
    const [quantity, setQuantity] = useState(1);

    const getArtPiece = useCallback(async (id: string) => {
        setLoading(true);
        try {
            const response = await artPieceService.getArtPiece(id);
            if (response?.status === 200) {
                const data: IArtPieceResponse = response?.data;
                setArtPiece(data);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (artPieceId) {
            getArtPiece(artPieceId);
        }
    }, [artPieceId, getArtPiece]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <div className="header">{artPiece?.title}</div>
            <Container>
                <Row className="mb-5">
                    <Col xs={12} className="my-3">
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(-1)}
                        />
                    </Col>
                    <Col xs={12} md={6} lg={4} className="mb-3">
                        <img
                            src={`/api${artPiece?.image}`}
                            alt="art-piece"
                            width="100%"
                            height="auto"
                        />
                    </Col>
                    <Col xs={12} md={6} lg={8}>
                        <div className="price mb-3">
                            GHC {artPiece?.price.toFixed(2)}
                        </div>
                        <div className="text mb-4">{artPiece?.brief}</div>
                        <div className="button-container">
                            <input
                                type="number"
                                min={1}
                                value={quantity}
                                onChange={(e) =>
                                    setQuantity(Number.parseInt(e.target.value))
                                }
                            />
                            <span className="mx-4">
                                {artPiece && (
                                    <AddToCartButton
                                        artPiece={artPiece}
                                        quantity={quantity}
                                    />
                                )}
                            </span>
                        </div>
                    </Col>
                </Row>
                {artPiece && artPiece.categoryId && (
                    <RelatedArtPieces
                        categoryId={artPiece.categoryId}
                        artPieceId={artPiece._id}
                    />
                )}
            </Container>
        </div>
    );
};
