import React from "react";

import image1 from "../../../assets/goddess.jpg";
import image2 from "../../../assets/illuminate.jpg";
import image3 from "../../../assets/in_the_moment.jpg";
import image4 from "../../../assets/mr_manhattan.jpg";
import image5 from "../../../assets/the_man.jpg";
import image6 from "../../../assets/she_who_carries.jpg";

import {
    Carousel,
    CarouselItem,
} from "components/sub-components/carousel/Carousel";
import { useMediaQuery } from "components/utils/media-query-hook/MediaQueryHook";

export const Home = () => {
    const isMobile = useMediaQuery("(max-width: 480px)");
    const isTablet = useMediaQuery("(max-width: 990px)");

    const landingImages = [image1, image2, image3, image4, image5, image6];

    return (
        <div>
            <Carousel show={isMobile ? 1 : isTablet ? 3 : 4}>
                {landingImages.map((image, idx) => (
                    <CarouselItem key={idx} height={"88vh"} image={image} />
                ))}
            </Carousel>
        </div>
    );
};
